import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider, FormLabel, Input, InputLabel, Select,} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import {translateRoutine} from "./helpers";
import LoadingButton from "@mui/lab/LoadingButton";
import AddTaskIcon from "@mui/icons-material/AddTask";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function ApplicationFiles(props) {
    const navigate = useNavigate();

    const [routine, setRoutine] = React.useState('');
    const [cardFile, setCardFile] = React.useState();
    const [musicFile, setMusicFile] = React.useState();
    const [isCardUploadSuccess, setIsCardUploadSuccess] = React.useState(false);
    const [isMusicUploadSuccess, setIsMusicUploadSuccess] = React.useState(false);
    const [cardLoadingError, setCardLoadingError] = React.useState();
    const [musicLoadingError, setMusicLoadingError] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false)
    const [isCardFileExists, setIsCardFileExists] = React.useState(true);
    const [isMusicFileExists, setIsMusicFileExists] = React.useState(true);
    const [isMusicDownloadLoading, setIsMusicDownloadLoading] = React.useState(false);
    const [isMusicDeleteLoading, setIsMusicDeleteLoading] = React.useState(false);
    const [isCardDownloadLoading, setIsCardDownloadLoading] = React.useState(false);
    const [isCardDeleteLoading, setIsCardDeleteLoading] = React.useState(false);


    const freeRoutinesArray = Object.keys(props.application.free).flatMap(key =>
        props.application.free[key].map(item => ({...item, routine: `free-${key}`})))

    const techRoutinesArray = Object.keys(props.application.tech).flatMap(key =>
        props.application.tech[key].map(item => ({...item, routine: `tech-${key}`})))

    const [allRoutinesArray, setAllRoutinesArray] = React.useState([...freeRoutinesArray, ...techRoutinesArray]);

    const shouldRenderError = props.application.teamName ? false : 'Что-то пошло не так. Удалите заявку и создайте её заново'

    const handleRoutineChange = (event) => {
        setRoutine(event.target.value);
        setCardFile(null)
        setMusicFile(null)
        setMusicLoadingError(null)
        setCardLoadingError(null)
        setIsMusicUploadSuccess(false)
        setIsCardUploadSuccess(false)
    };


    const handleCardFileChange = (event) => {
        setCardFile(event.target.files[0]);
    };
    const handleMusicFileChange = (event) => {
        setMusicFile(event.target.files[0]);
    };

    const sendCardFile = async () => {
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        if (!id || !teamName) return;
        const routineName = allRoutinesArray.find(item => item.id === id && item.routine === routineType).routine;
        const formData = new FormData();
        formData.append('file', cardFile);
        formData.append('competition', document.location.href.split('/')[4])
        formData.append('routine', routineName);
        formData.append('teamName', teamName)
        fetch(`/api/cards/upload`, {
            method: "POST",
            credentials: "include",
            body: formData
        })
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    return res
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json();
                }
            })
            .then((response) => {
                setIsCardUploadSuccess(true);
            })
            .catch(async (err) => {
                let error = await err
                setCardLoadingError(error.message)
            })
    }

    const sendMusicFile = async () => {
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const routineName = allRoutinesArray.find(item => item.id === id && item.routine === routineType).routine;
        const formData = new FormData();
        formData.append('file', musicFile);
        formData.append('competition', document.location.href.split('/')[4])
        formData.append('routine', routineName);
        formData.append('teamName', teamName)
        fetch(`/api/music/upload`, {
            method: "POST",
            credentials: "include",
            body: formData
        })
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    return res
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json();
                }
            })
            .then((response) => {
                setIsMusicUploadSuccess(true);
            })
            .catch(async (err) => {
                let error = await err
                setMusicLoadingError(error.message)
            })
    }

    const sendFiles = async () => {
        if (isCardFileExists && isMusicFileExists) {
            setAllRoutinesArray((oldArray) => {
                const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
                const newArray = oldArray.filter(item => item.id !== id || item.routine !== routineType);
                return newArray;
            })
            props.showAlert('success', 'Успешно');
            setRoutine('');
            setIsLoading(false);
            return;
        }
        await sendCardFile();
        await sendMusicFile();
    }
    const confirmFileLoading = () => {
        setIsLoading(true);
        sendFiles().then();
    }

    const isCardFileExistInCurrentRoutine = () => {
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const competition = document.location.href.split('/')[4];
        fetch(`/api/cards/exists/${competition}/${routineType}/${teamName}`,
            {
                method: "GET",
                credentials: "include",
            })
            .then(res => {
                switch (res.status) {
                    case 403:
                        setIsCardFileExists(true);
                        break;
                    case 200:
                        setIsCardFileExists(false);
                        break;
                    default:
                        setIsCardFileExists(true);
                        break;
                }
            })
    }

    const isMusicFileExistInCurrentRoutine = () => {
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const competition = document.location.href.split('/')[4];
        fetch(`/api/music/exists/${competition}/${routineType}/${teamName}`,
            {
                method: "GET",
                credentials: "include",
            })
            .then(res => {
                switch (res.status) {
                    case 403:
                        setIsMusicFileExists(true);
                        break;
                    case 200:
                        setIsMusicFileExists(false);
                        break;
                    default:
                        setIsMusicFileExists(true);
                        break;
                }
            })
    }

    const downloadMusicFile = () => {
        setIsMusicDownloadLoading(true);
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const competition = document.location.href.split('/')[4];
        fetch(`/api/music/${competition}/${routineType}/${teamName}`, {
            credentials: "include"
        })
            .then(res => {
                if (res.status === 200) {
                    return res.arrayBuffer()
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json()
                }
            })
            .then((response) => {
                let blob = new Blob([response], {type: "audio/mp3"})
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                const name = `${routine.split('-').map(translateRoutine).join(' ')} ${teamName}`
                link.download = `${name}.mp3`;
                link.click();
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
            .finally(() => {
                setIsMusicDownloadLoading(false);
            })
    }

    const deleteMusicFile = () => {
        setIsMusicDeleteLoading(true);
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const competition = document.location.href.split('/')[4];
        fetch(`/api/music/${competition}/${routineType}/${teamName}`, {
            method: "DELETE",
            credentials: "include"
        })
            .then(res => {
                if (res.status === 200) {
                    props.showAlert('success', 'Музыкальный файл успешно удален')
                    setIsMusicFileExists(false);
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json();
                }
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
            .finally(() => {
                setIsMusicDeleteLoading(false);
            })
    }


    const downloadCardFile = () => {
        setIsCardDownloadLoading(true);
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const competition = document.location.href.split('/')[4];
        fetch(`/api/cards/${competition}/${routineType}/${teamName}`, {
            credentials: "include"
        })
            .then(res => {
                if (res.status === 200) {
                    return res.arrayBuffer()
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json()
                }
            })
            .then((response) => {
                let blob = new Blob([response], {type: "audio/mp3"})
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                const name = `${routine.split('-').map(translateRoutine).join(' ')} ${teamName}`
                link.download = `${name}.mp3`;
                link.click();
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
            .finally(() => {
                setIsCardDownloadLoading(false);
            })
    }

    const deleteCardFile = () => {
        setIsCardDeleteLoading(true);
        const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
        const competition = document.location.href.split('/')[4];
        fetch(`/api/cards/${competition}/${routineType}/${teamName}`, {
            method: "DELETE",
            credentials: "include"
        })
            .then(res => {
                if (res.status === 200) {
                    props.showAlert('success', 'Музыкальный файл успешно удален')
                    setIsMusicFileExists(false);
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json();
                }
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
            .finally(() => {
                setIsCardDeleteLoading(false);
            })
    }


    useEffect(() => {
        if (isCardUploadSuccess && isMusicUploadSuccess) {
            setAllRoutinesArray((oldArray) => {
                const [id, teamName, routineType] = routine ? routine.split(':') : [null, null, null];
                const newArray = oldArray.filter(item => item.id !== id || item.routine !== routineType);
                return newArray;
            })
            props.showAlert('success', 'Файлы загружены успешно');
            setRoutine('')
        } else {
            if (cardLoadingError)
                props.showAlert('error', cardLoadingError);
            if (musicLoadingError)
                props.showAlert('error', musicLoadingError);
        }
        setIsLoading(false);
    }, [cardLoadingError, musicLoadingError, isCardUploadSuccess, isMusicUploadSuccess])

    useEffect(() => {
        if (allRoutinesArray.length === 0)
            props.handleAllFilesUpload();
    }, [allRoutinesArray])

    useEffect(() => {
        isMusicFileExistInCurrentRoutine();
        isCardFileExistInCurrentRoutine();
    }, [routine])

    return (
        shouldRenderError || <Container>
            <Typography variant={"h5"} textAlign={"center"}>
                {props.application && props.application?.teamName}
            </Typography>
            <Divider variant='fullWidth'/>
            <Grid container spacing={1}>
                {
                    allRoutinesArray.length !== 0 &&
                    <Grid item xs={12} justifyContent={"center"}>
                        <FormControl variant={'filled'} sx={{m: 1}} fullWidth>
                            <InputLabel id="routine-label">Программы</InputLabel>
                            <Select
                                labelId="routine-label"
                                id="routine"
                                value={routine}
                                onChange={handleRoutineChange}
                                fullWidth
                                label="Age"
                            >
                                {
                                    allRoutinesArray.map(item => {
                                        const [type, routine] = item.routine.split('-');
                                        const typeName = type === 'tech' ? 'Тех.' : 'Пр.'
                                        if (routine === 'solo') {
                                            const athleteName = `${item.athlete.family} ${item.athlete.name[0]}. ${item.athlete.surname[0]}.`
                                            const name = `${typeName} Соло ${athleteName}`
                                            const value = `${item.id}:${athleteName}:${item.routine}`
                                            return <MenuItem key={value} value={value}>{name}</MenuItem>
                                        }
                                        if (routine === 'duet' || routine === 'mixed') {
                                            const athleteFamilies = item.athletes.map(athlete => athlete.family).join('-')
                                            const name = `${typeName} ${translateRoutine(routine)} ${athleteFamilies}`
                                            const value = `${item.id}:${athleteFamilies}:${item.routine}`
                                            return <MenuItem key={value} value={value}>{name}</MenuItem>
                                        }
                                        const name = `${typeName} ${translateRoutine(routine)}`
                                        const value = `${item.id}:${props.application.teamName}:${item.routine}`
                                        return <MenuItem key={value} value={value}>{name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Grid item container flexDirection={'column'}>
                            {
                                props.application && routine &&
                                <Grid item xs={12}>
                                    {isCardFileExists ?
                                        <Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'start',
                                            }}>
                                                <Typography>
                                                    Карточка тренера уже загружена
                                                </Typography>
                                                <AddTaskIcon color={'success'}/>
                                            </Box>
                                            <Box sx={{
                                                gap: 1
                                            }}>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    size={'large'}
                                                    sx={{margin: 1}}
                                                    variant={'contained'}
                                                    endIcon={<DownloadIcon/>}
                                                    onClick={downloadCardFile}
                                                    color="success"
                                                >
                                                    Скачать
                                                </LoadingButton>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    size={'large'}
                                                    sx={{margin: 1}}
                                                    variant={'contained'}
                                                    endIcon={<DeleteForeverIcon/>}
                                                    onClick={deleteCardFile}
                                                    color="error"
                                                >
                                                    Удалить
                                                </LoadingButton>
                                            </Box>
                                        </Box> :
                                        <FormControl sx={{marginTop: 4}} variant={'filled'}>
                                            <FormLabel id="card-label">
                                                Загрузите заполненный файл карточки тренера
                                            </FormLabel>
                                            <Input
                                                variant={'outlined'}
                                                id="card"
                                                inputProps={{
                                                    multiple: false
                                                }}
                                                type={'file'}
                                                onChange={handleCardFileChange}
                                            />
                                        </FormControl>
                                    }
                                </Grid>
                            }
                            {
                                props.application && routine &&
                                <Grid item>
                                    {isMusicFileExists ?
                                        <Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'start',
                                            }}>
                                                <Typography>
                                                    Музыка уже загружена
                                                </Typography>
                                                <AddTaskIcon color={'success'}/>
                                            </Box>
                                            <Box sx={{
                                                gap: 1
                                            }}>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    size={'large'}
                                                    sx={{margin: 1}}
                                                    variant={'contained'}
                                                    endIcon={<DownloadIcon/>}
                                                    onClick={downloadMusicFile}
                                                    color="success"
                                                >
                                                    Скачать
                                                </LoadingButton>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    size={'large'}
                                                    sx={{margin: 1}}
                                                    variant={'contained'}
                                                    endIcon={<DeleteForeverIcon/>}
                                                    onClick={deleteMusicFile}
                                                    color="error"
                                                >
                                                    Удалить
                                                </LoadingButton>
                                            </Box>
                                        </Box> :
                                        <FormControl sx={{marginTop: 4}} variant={'filled'}>
                                            <FormLabel id="file-label">
                                                Загрузите заполненный файл с музыкой (в формате mp3)
                                            </FormLabel>
                                            <Input
                                                variant={'outlined'}
                                                id="music"
                                                inputProps={{
                                                    accept: '.mp3',
                                                    multiple: false
                                                }}
                                                type={'file'}
                                                onChange={handleMusicFileChange}
                                                disabled={isMusicFileExists}
                                            />
                                        </FormControl>
                                    }
                                </Grid>
                            }
                            <Grid item>
                                <LoadingButton
                                    loading={isLoading}
                                    size={'large'}
                                    fullWidth
                                    sx={{marginTop: 2, marginBottom: 1}}
                                    variant={'contained'}
                                    endIcon={<AddTaskIcon/>}
                                    onClick={confirmFileLoading}
                                    color="primary"
                                    disabled={!((cardFile || isCardFileExists) && (musicFile || isMusicFileExists))}
                                >
                                    Подтвердить
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container>
    )

}

export default ApplicationFiles
